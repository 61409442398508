import { AppConfigService } from '@questrade/itp-ng-core';
import { environment } from '../environments/environment';
import { APP_NAME, APP_PORT } from './constants';
import { ApiEndpoints } from '@qi-itp-ui-customer-journey/shared';

const baseHref = environment.production ? `` : `:${APP_PORT}`;
export const BASE_REF = `${location.protocol}//${location.hostname}${baseHref}`;

export const APP_ENDPOINTS = {
  LABELS_BUNDLE: `${BASE_REF}/data/labels.en.json`,
  OPTIONS_BUNDLE: `${BASE_REF}/data/options.en.json`,
  LANGUAGES: `${BASE_REF}/data/lang.json`,
  APP_CONFIG: `${BASE_REF}/data/${environment.production ? 'app-config.prod.json' : 'app-config.json'}`
};

// Set Remote App Urls
export let REMOTE_URLS: {
  'itp-home-journey': string;
  'itp-auto-journey': string;
  'itp-pre-journey': string;
  'itp-common': string;
  'itp-sme-journey': string;
};

if (environment.production) {
  REMOTE_URLS = {
    'itp-home-journey': `${BASE_REF}/itp-home-journey`,
    'itp-auto-journey': `${BASE_REF}/itp-auto-journey`,
    'itp-pre-journey': `${BASE_REF}/itp-pre-journey`,
    'itp-common': `${BASE_REF}/itp-common`,
    'itp-sme-journey': `${BASE_REF}/itp-sme-journey`
  };
} else {
  REMOTE_URLS = {
    'itp-home-journey': 'https://ti-itp-cj.local.questrade.com:4201',
    'itp-auto-journey': 'https://ti-itp-cj.local.questrade.com:4202',
    'itp-pre-journey': 'https://ti-itp-cj.local.questrade.com:4203',
    'itp-common': 'https://ti-itp-cj.local.questrade.com:4204',
    'itp-sme-journey': 'https://ti-itp-cj.local.questrade.com:4205'
  };
}

export const ENV_PREFIX: { [key: string]: string } = {
  sit: 'sit.',
  uat: 'uat.',
  prod: ''
};

export const getApiEndpoints = (env: string = 'sit') => {
  return {
    CONTACT_CENTER: `https://itp-ti.${ENV_PREFIX[env]}questrade.com/v1/insurance-customer/contact-center`,
    PROCESS_CUSTOMER: `https://itp-ti.${ENV_PREFIX[env]}questrade.com/v1/process-customer/customers`
  } as ApiEndpoints;
};

export const getApiEndpointsFactory = (appConfigService: AppConfigService) => {
  const env = appConfigService.appConfigMap.get(APP_NAME)?.env;

  return getApiEndpoints(env);
};
